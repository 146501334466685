import React from 'react';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import styled from 'styled-components';
import { Typography, Grid, Fade, Button } from '@material-ui/core';
import GatsbyLink from 'gatsby-link';

import MiniRose from '../images/new/mini_rose.jpg';
import MiniJaune from '../images/new/mini_jaune.jpg';
import MiniVert from '../images/new/mini_vert.jpg';
import MiniBleu from '../images/new/mini_bleu.jpg';
import theme from '../theme';

const SectionTitle = styled(Typography)`
  &&&& {
    font-size: 18px;
    font-family: Quicksand;
    font-weight: 500;
  }
`;

const SectionWrapper = styled.div<{ color: string; selected: boolean }>`
  padding: 17px;
  box-shadow: 0 2px 8px 0
    ${p => (p.selected ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0)')};
  border-radius: 5px;
  margin-bottom: 15px;
  transition: box-shadow 0.3s, transform 0.3s, background-color 0.3s;
  cursor: pointer;
  user-select: none;

  ${p =>
    p.selected &&
    `
      background-color: #fafafa;
      transform: translateY(-3px);
`} ${SectionTitle} {
    margin-bottom: 5px;
    color: ${p => (p.selected ? p.color : theme.palette.grey[1000])};
    transition: color 0.3s;
  }

  ${SectionTitle} {
    color: ${p => p.color};
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 2px 8px 0
      ${p => (p.selected ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.1)')};
    background-color: #fafafa;
  }

  ${theme.breakpoints.down('sm')} {
    transform: translateY(-3px);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    ${SectionTitle} {
      color: ${p => p.color};
    }
  }
`;

const FeatureLink = styled(GatsbyLink)`
  font-family: Roboto;
  color: ${theme.palette.blue.main};
  text-decoration: none;
  font-weight: 300;

  transition: color 0.3s;

  &:hover {
    color: ${theme.palette.primary.main};
  }
`;

const SectionContent = styled(Typography)`
  &&&& {
    font-size: 16px;
    font-weight: 300;
  }
`;

const Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dot = styled.div<{ selected: boolean }>`
  cursor: pointer;
  margin: 3px;
  background-color: ${p =>
    p.selected ? theme.palette.grey[600] : theme.palette.grey[300]};
  transition: background-color 0.2s;
  height: 10px;
  width: 10px;
  border-radius: 50%;
`;

interface Props {
  title: string;
  icon: string;
  description: string;
  subtitle: string;
  color: string;
  subSections: string[];
  previous?: string;
  next?: string;
  // tslint:disable-next-line:prefer-array-literal
  sections: Array<{ title: string; description: string; image: string }>;
}

@observer
class Feature extends React.Component<Props> {
  @observable
  public section: number = 0;

  @observable
  public in: boolean = true;

  public onChange = (value: number) => () => {
    runInAction(() => {
      this.section = value;
      this.in = false;
    });

    setTimeout(() => {
      runInAction(() => {
        this.in = true;
      });
    }, 0);
  }

  public render() {
    return (
      <div style={{ marginTop: '60px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={this.props.icon}
            style={{ marginRight: '20px', height: '40px' }}
          />
          <Typography style={{ color: this.props.color }} variant="display1">
            {this.props.title}
          </Typography>
        </div>

        <Grid
          container
          spacing={32}
          style={{ marginTop: '30px' }}
          alignItems="center"
        >
          <Grid item xs={12} md={7}>
            <Typography
              style={{
                color: theme.palette.blue.main,
                fontWeight: 500,
                fontSize: '20px',
                fontFamily: 'Quicksand',
              }}
            >
              {this.props.subtitle}
            </Typography>
            <Typography
              style={{ marginTop: '8px', fontSize: '17px', fontWeight: 300 }}
            >
              {this.props.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} style={{ paddingLeft: '50px' }}>
            <Typography
              style={{
                fontSize: '18px',
                color: theme.palette.secondary.main,
              }}
            >
              <img
                src={MiniJaune}
                style={{
                  height: '20px',
                  width: '20px',
                  verticalAlign: 'middle',
                  marginRight: '12px',
                }}
              />
              {this.props.subSections[0]}
            </Typography>
            <Typography
              style={{
                marginTop: '20px',
                fontSize: '18px',
                color: theme.palette.green.main,
              }}
            >
              <img
                src={MiniVert}
                style={{
                  height: '20px',
                  width: '20px',
                  verticalAlign: 'middle',
                  marginRight: '12px',
                }}
              />
              {this.props.subSections[1]}
            </Typography>
            <Typography
              style={{
                marginTop: '20px',
                fontSize: '18px',
                color: theme.palette.primary.main,
              }}
            >
              <img
                src={MiniRose}
                style={{
                  height: '20px',
                  width: '20px',
                  verticalAlign: 'middle',
                  marginRight: '12px',
                }}
              />
              {this.props.subSections[2]}
            </Typography>
            {this.props.subSections.length > 3 && (
              <Typography
                style={{
                  marginTop: '20px',
                  fontSize: '18px',
                  color: theme.palette.blue.main,
                }}
              >
                <img
                  src={MiniBleu}
                  style={{
                    height: '20px',
                    width: '20px',
                    verticalAlign: 'middle',
                    marginRight: '12px',
                  }}
                />
                {this.props.subSections[3]}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={24}
          style={{ marginTop: '70px', marginBottom: '0px' }}
        >
          <Grid item xs={12} md={4}>
            <SectionWrapper
              selected={this.section === 0}
              onClick={this.onChange(0)}
              color={theme.palette.secondary.main}
            >
              <SectionTitle>{this.props.sections[0].title}</SectionTitle>
              <SectionContent>
                {this.props.sections[0].description}
              </SectionContent>
            </SectionWrapper>
            <SectionWrapper
              selected={this.section === 1}
              onClick={this.onChange(1)}
              color={theme.palette.primary.main}
            >
              <SectionTitle>{this.props.sections[1].title}</SectionTitle>
              <SectionContent>
                {this.props.sections[1].description}
              </SectionContent>
            </SectionWrapper>
            <SectionWrapper
              selected={this.section === 2}
              onClick={this.onChange(2)}
              color={theme.palette.green.main}
            >
              <SectionTitle>{this.props.sections[2].title}</SectionTitle>
              <SectionContent>
                {this.props.sections[2].description}
              </SectionContent>
            </SectionWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            className="desktop-only"
            style={{ alignSelf: 'center' }}
          >
            <Fade in={this.in} timeout={{ enter: 800, exit: 0 }}>
              <img
                src={this.props.sections[this.section].image}
                style={{
                  maxWidth: '100%',
                }}
              />
            </Fade>
            <Dots>
              {this.props.sections.map((data, i) => (
                <Dot
                  selected={i === this.section}
                  onClick={() =>
                    runInAction(() => {
                      this.section = i;
                    })
                  }
                />
              ))}
            </Dots>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '50px',
          }}
        >
          {this.props.previous ? (
            <FeatureLink to={this.props.previous}>
              <i className="fas fa-angle-left fa-fw" />
              Précédent
            </FeatureLink>
          ) : (
            <div />
          )}
          {this.props.next ? (
            <FeatureLink to={this.props.next}>
              Suivant
              <i className="fas fa-angle-right fa-fw" />
            </FeatureLink>
          ) : (
            <div />
          )}
        </div>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            marginTop: '20px',
            marginBottom: '100px',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <Button
            style={{
              margin: '10px',
            }}
            color="primary"
            variant="contained"
            component={(props: any) => (
              <GatsbyLink
                to="/demo"
                {...props}
                onClick={() => {
                  gtag_create();
                }}
              />
            )}
          >
            Essayer gratuitement Campus
          </Button>
          <Button
            style={{
              margin: '10px',
              backgroundColor: theme.palette.green.main,
              color: 'white',
            }}
            variant="contained"
            component={(props: any) => (
              <GatsbyLink
                to="/contact"
                {...props}
                onClick={() => {
                  gtag_contact();
                }}
              />
            )}
          >
            Contacter notre équipe
          </Button>
        </div>
      </div>
    );
  }
}

export default Feature;
