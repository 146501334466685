import React from 'react';

import Layout from '../../components/Layout';
import Feature from '../../components/Feature';
import theme from '../../theme';
import OpenInnoIcon from '../../images/openinno.jpg';
import B1 from '../../images/new/open_fct1.jpg';
import B2 from '../../images/new/open_fct2.jpg';
import B3 from '../../images/new/open_fct3.jpg';

const OpenInnoPage = () => (
  <Layout>
    <Feature
      previous="/features/challenges"
      next="/features/dashboard"
      color={theme.palette.green.main}
      title="Open Innovation"
      icon={OpenInnoIcon}
      subtitle="Un partenaire extérieur a forcément la solution à vos problématiques."
      description="Bénéficiez d’expertise externe en challengeant d’autres organisation (PME, ETI, Grands Groupes, Startup, Fablabs, Incubateurs, etc.).  Diminuez ainsi vos risques et créez de nouveaux partenariats."
      subSections={[
        'Trouver rapidement des solutions',
        'Interroger vos clients et fournisseurs',
        'Créer de nouveaux partenariats',
      ]}
      sections={[
        {
          title: 'Création de challenges externes',
          description:
            'L’entreprise créé facilement des challenges externes à diffuser auprès de leurs clients, fournisseurs et écosystème.',
          image: B1,
        },
        {
          title: 'Gestion simplifiée',
          description:
            'Réceptionnez facilement les participations à votre challenge et sélectionnez le ou les vainqueurs.',
          image: B2,
        },
        {
          title: 'Création de partenariats',
          description:
            'Bénéficiez d’un canal dédié pour collaborer avec chaque vainqueur via une messagerie et dépôt de fichiers sécurisés.',
          image: B3,
        },
      ]}
    />
  </Layout>
);

export default OpenInnoPage;
